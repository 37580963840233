<template>
  <div class="ResourceList">
    <list
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
        <v-button text="导入" permission="import" @click="importData" />
      </template>
      <template #searchSlot>
        <v-select label="方法" v-model="searchParams.method" :options="methodOps" />
        <v-input label="URL" v-model="searchParams.url" :maxlength="100" :width="300"/>
        <v-select label="是否关联菜单" v-model="searchParams.hasMenuRelated" :options="hasMenuRelatedOps" />
        <v-select label="权限校验" v-model="searchParams.interceptEnable" :options="interceptEnableOps" />
        <v-select label="资源所属平台" v-model="searchParams.appType" :options="typeOps" />
        <v-datepicker label="添加时间段" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.inTimeFrom" :endTime.sync="searchParams.inTimeTo" />
        <v-datepicker label="修改时间段" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.updateTimeFrom" :endTime.sync="searchParams.updateTimeTo" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" permission="update" type="text" @click="edit(scope.row)"/>
        <v-button text="关联菜单" permission="relateMenu" type="text" @click="related(scope.row)"/>
      </template>
    </list>
    <el-dialog width="850px" title="关联菜单" :visible.sync="relatedResourceVisible">
      <div class="pop-wrapper">
        <form-panel
          v-bind="submitConfig"
          :hasHeader="false"
          :form="form"
          :submitBefore="submitBefore"
          :submitSuccess="submitSuccess"
        >
          <col2-detail>
            <div v-for="(item, index) in form.relatedMenuIds" :key="item.id">
              <!-- <el-form-item label="所属平台" :rules="[{ required: true, message: '请选择所属平台', trigger: 'change' }]" :prop="'relatedMenuIds.' + index + '.type'">
                <v-select2 v-model="item.type" :key="'resourceSelect2Type_' + item.id" v-bind="typeParams" placeholder="查询所属平台" :width="width" />
              </el-form-item> -->
              <el-form-item label="上级菜单" :rules="[{ required: false, message: '请选择上级菜单', trigger: 'change' }]" :prop="'relatedMenuIds.' + index + '.parentId'">
                <v-select2 v-model="item.parentId" :key="'resourceSelect2ParentId_' + item.id" v-bind="parentMenuParams" :subjoin="parentMenuSubjoin(item)" placeholder="查询上级菜单" :width="width" />
              </el-form-item>
              <el-form-item label="菜单名称" :rules="[{ required: false, message: '请选择菜单名称', trigger: 'change' }]" :prop="'relatedMenuIds.' + index + '.menuId'">
                <v-select2 class="inline-block" v-model="item.menuId" :key="'resourceSelect2MenuId_' + item.id" v-bind="menuParams" :subjoin="menuSubjoin(item)" :disabled="!item.parentId" placeholder="查询菜单名称" :width="width" />
                <v-button class="button" v-if="form.relatedMenuIds.length > 1" type="danger" text="删除" @click="reduceData(index)" />
                <v-button class="button" v-if="form.relatedMenuIds.length < 10 && index === form.relatedMenuIds.length - 1" text="增加" @click="increaseData" />
              </el-form-item>
            </div>
          </col2-detail>
        </form-panel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    queryListURL,
    exportListURL,
    editURL,
    queryDetailURL,
    queryMenuListURL,
    selectSubMenuURL,
    getResourceAppType
  } from './api'
import { setMethodOps, methodMap, setHasMenuRelatedOps, hasMenuRelatedMap, setInterceptEnableOps, interceptEnableMap,
  appTypeMap } from './map'
import { Col2Detail } from 'components/bussiness'
  import { mapHelper } from '../../../common/utils'

export default {
  name: 'ResourceList',
  components: {
    Col2Detail
  },
  data () {
    let _this = this
    return {
      width: 400,
      maxDate: new Date(),
      searchUrl: queryListURL,
      exportUrl: exportListURL,
      methodOps: setMethodOps(1),
      typeOps: [],
      hasMenuRelatedOps: setHasMenuRelatedOps(1),
      interceptEnableOps: setInterceptEnableOps(1),
      searchParams: {
        method: undefined,
        url: '',
        hasMenuRelated: undefined,
        inTimeFrom: '',
        inTimeTo: '',
        updateTimeFrom: '',
        updateTimeTo: '',
        appType: undefined
      },
      headers: [
        {
          prop: 'methodText',
          label: '方法',
          width: 50,
          formatter (row) {
            return methodMap[row.method]
          }
        },
        {
          prop: 'url',
          label: 'URL',
          minWidth: 150
        },
        {
          prop: 'remarks',
          label: '备注'
        },
        {
          prop: 'hasMenuRelatedText',
          label: '是否关联菜单',
          formatter (row) {
            const hasMenuRelated = row.relatedMenuCount > 0
            return hasMenuRelatedMap[hasMenuRelated]
          }
        },
        {
          prop: 'relatedMenuName',
          label: '关联的菜单'
        },
        {
          prop: 'interceptEnableText',
          label: '权限校验',
          formatter (row) {
            return interceptEnableMap[row.interceptEnable]
          }
        },
        {
          prop: 'appTypeText',
          label: '资源所属平台',
          formatter (row) {
            if (_this.typeOps) {
              let result = _this.typeOps.filter(item => Number(item.value) === Number(row.appType))
              if (result && result.length > 0) {
                return result[0].text
              }
            }
            return ''
          }
        },
        {
          prop: 'inUserName',
          label: '添加人'
        },
        {
          prop: 'inTime',
          label: '添加时间'
        },
        {
          prop: 'updateUserName',
          label: '修改人'
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        }
      ],
      relatedResourceVisible: false,
      parentMenuParams: {
        searchUrl: queryMenuListURL,
        request: {
          text: 'menuName',
          value: 'id'
        },
        response: {
          text: 'menuName',
          value: 'id'
        }
      },
      menuParams: {
        searchUrl: selectSubMenuURL,
        method: 'POST',
        contentType: 'application/json',
        request: {
          text: 'menuName',
          value: 'menuId'
        },
        response: {
          text: 'menuName',
          value: 'id'
        }
      },
      form: {
        id: undefined,
        url: '',
        method: undefined,
        remarks: '',
        interceptEnable: undefined,
        relatedMenuIds: [],
        appType: undefined,
        desensityRule: ''
      },
      submitConfig: {
        submitMethod: '',
        submitUrl: ''
      }
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  created() {
    this.getAppRequestTypeOps()
  },
  methods: {
    getAppRequestTypeOps () {
      this.$axios({
        method: 'GET',
        url: getResourceAppType
      }).then(res => {
        const { data, status } = res
        let typeList = []
        if (status === 100) {
          let list = data || []
          typeList = list.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          }) || []
        }
        const { setOps: typeOps } = mapHelper.setMap(typeList)
        this.typeOps = typeOps(1)
      })
    },
    checkPermission () {
      if (this.userType !== '106') {
        this.$message.error('用户无操作权限!')
        return false
      }
      return true
    },
    create () {
      if (this.checkPermission()) {
        this.$router.push({
          name: 'resourceForm'
        })
      }
    },
    edit (row) {
      if (this.checkPermission()) {
        this.$router.push({
          name: 'resourceForm',
          query: {
            id: row.id
          }
        })
      }
    },
    // 关联资源方法，属于编辑
    related (row) {
      if (this.checkPermission()) {
        let newForm = {
          id: undefined,
          url: '',
          method: undefined,
          remarks: '',
          interceptEnable: undefined,
          relatedMenuIds: [],
          appType: undefined,
          desensityRule: ''
        }
        this.form = newForm // 每次请求前把显示数据还原到初始状态
        this.submitConfig.submitMethod = 'PUT'
        this.submitConfig.submitUrl = editURL
        let _this = this
        let queryConfig = {
          method: 'GET',
          url: `${queryDetailURL}/${row.id}`
        }
        _this.$axios(queryConfig).then(res => {
          if (res.status == 100) { // eslint-disable-line
            let data = res.data
            _this.update(data)
          }
        })
        this.relatedResourceVisible = true
      }
    },
    importData () {
      if (this.checkPermission()) {
        this.$router.push({
          name: 'resourceFormImport'
        })
      }
    },
    createId () {
      return new Date().valueOf().toString()
    },
    // 添加
    increaseData () {
      this.form.relatedMenuIds.push({
        id: this.createId()
      })
    },
    // 删除
    reduceData (index) {
      this.form.relatedMenuIds.splice(index, 1)
    },
    parentMenuSubjoin (item) {
      return {
        topParentId: item.type,
        menuType: 2,
        specialNo: this.form.appType === 2 ? 'wgMenuFirstMenu' : ''
      }
    },
    menuSubjoin (item) {
      return {
        parentId: item.parentId,
        menuType: 3
      }
    },
    update (data) {
      if (data) {
        data.appType = parseInt(data.appType)
      }
      Object.keys(this.form).forEach(key => {
        if (data[key] !== undefined) {
          this.form[key] = data[key]
        }
      })
      if (data.relatedMenus && data.relatedMenus.length < 1) {
        this.increaseData()
      } else {
        if (data.relatedMenus && data.relatedMenus.length) {
          data.relatedMenus.forEach((item, index) => {
            item['id'] = this.createId() + index
          })
        }
        this.form.relatedMenuIds = data.relatedMenus
      }
    },
    submitBefore (data) {
      const relatedMenuIds = []
      data['relatedMenuIds'].forEach(item => {
        if (item.menuId) {
          relatedMenuIds.push(item.menuId)
        }
      })
      data['relatedMenuIds'] = relatedMenuIds

      let type = ''
      if (this.typeOps) {
        let result = this.typeOps.filter(item => Number(item.value) === Number(data.appType))
        if (result && result.length > 0) {
          type = result[0].text
        }
      }

      let dataObject = `${data.url}-${type}-${data.method}`
      this.$set(data, 'dataObject', dataObject)
      return true
    },
    submitSuccess () {
      this.$message.success('保存成功')
      this.relatedResourceVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-wrapper {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  .inline-block {
    display: inline-block;
  }
  .button {
    margin-left: 20px;
  }
  .form-panel-container {
    ::v-deep {
      .el-col {
        width: 100% !important;
      }
      .el-form-item__label {
        width: 120px !important;
      }
      .el-form-item__content {
        margin-left: 120px !important;
      }
      .footer-button {
        padding-left: 120px !important;
      }
    }
  }
}
</style>
